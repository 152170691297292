import {
  WppIconInfo,
  WppListItem,
  WppSpinner,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { AppAPI } from 'types/app/app'

import styles from './ToolbarItem.module.scss'
import IconPin from '../../../../assets/icons/pin.svg'
import { Flex } from '../../../common/flex/Flex'
import DynamicIconApp from '../../../common/icons/DynamicIconApp'

interface ToolbarItemProps {
  app: AppAPI
  appDisabled: boolean
  appDisabledProjectContext: boolean | undefined
  appHover: boolean
  setAppHover: (hover: boolean) => void
  handleOpenApp: (app: AppAPI) => void
  pinApp: () => void
  type: string
  isLoading: boolean
  appPinned: boolean
}

const ToolbarItem = ({
  app,
  appDisabled,
  appDisabledProjectContext,
  appHover,
  setAppHover,
  handleOpenApp,
  pinApp,
  type,
  isLoading,
  appPinned,
}: ToolbarItemProps) => {
  const [showShortName, setShowShortName] = useState(false)

  useEffect(() => {
    setShowShortName(!!appDisabledProjectContext && appHover)
  }, [appDisabledProjectContext, appHover])

  return (
    <WppListItem
      className={clsx(styles.itemContainer, appDisabled && styles.buttonDisabled, 'cancel-drag')}
      onMouseEnter={() => setAppHover(true)}
      onMouseLeave={() => setAppHover(false)}
      onClick={() => !appDisabled && handleOpenApp(app)}
    >
      <Flex key={app.id} gap={8} slot="label" className={clsx(appDisabled && styles.opacity)}>
        <DynamicIconApp name={app.iconUrl || 'WppIconApp'} slot="icon-start" />
        <WppTypography
          className={styles.appNameAll}
          tag="span"
          type="s-body"
          style={{ width: showShortName && type !== 'recommended-apps' ? '130px' : '160px' }}
        >
          {app.name}
        </WppTypography>
      </Flex>
      <Flex slot="right" className={styles.iconsContainer}>
        {appDisabledProjectContext && appHover ? (
          <WppTooltip
            className={clsx(styles.infoIcon, styles.iconWrapper)}
            text="This app cannot be launched outside of a project"
            onMouseEnter={() => setAppHover(true)}
            externalClass={styles.tooltip}
          >
            <WppIconInfo className={styles.infoIconRecommIcon} />
          </WppTooltip>
        ) : null}
        {type !== 'recommended-apps' && (
          <div className={styles.iconWrapper}>
            {isLoading ? (
              <WppSpinner />
            ) : (
              <WppTooltip text={appPinned ? 'Unpin app' : 'Pin app'}>
                <img
                  src={IconPin}
                  alt=""
                  className={styles.pointer}
                  onClick={(e: any) => {
                    pinApp()
                    e.stopPropagation()
                  }}
                />
              </WppTooltip>
            )}
          </div>
        )}
      </Flex>
    </WppListItem>
  )
}

export default ToolbarItem
